import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { 
    FaTicketAlt, 
    FaRobot, 
    FaBoxOpen, 
    FaShoppingCart, 
    FaChartLine,
    FaExternalLinkAlt,
    FaExclamationCircle,
    FaUsers,
    FaSmile
} from 'react-icons/fa';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const [user, setUser] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
    const [ticketStats, setTicketStats] = useState({
        total_tickets: 0,
        ai_resolved_tickets: 0,
        assigned_tickets: 0,
        open_tickets: 0,
        pending_tickets: 0,
        closed_tickets: 0,
        resolved_tickets: 0,
        automation_ratio: 0,
        automated_tickets: 0,
        remaining_tickets: 0,
        waiting_carrier_response: 0,
        new_tickets: 0,
        affected_tickets: 0,
        waiting_client_response: 0,
        user_type: ''
    });
    const [orders, setOrders] = useState([]);
    const [returns, setReturns] = useState([]);
    const [attentionProducts, setAttentionProducts] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const [expandedCards, setExpandedCards] = useState({});
    const [tagsStats, setTagsStats] = useState(null);
    const [clientStats, setClientStats] = useState({
        total_clients: 0,
        total_tickets: 0,
        most_active_client: null,
        clients: []
    });
    const [isStatsCardCollapsed, setIsStatsCardCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                fetchTickets(token);
            }
        };
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        const fetchAllData = async () => {
            try {
                setIsLoading(true);
                
                await Promise.all([
                    fetchUserData(token),
                    fetchTickets(token),
                    fetchMonthlyUsage(token),
                    fetchTicketStats(token),
                    fetchOrders(token),
                    fetchAttentionProducts(),
                    fetchReturns(token),
                    fetchTagsStatistics(token),
                    fetchClientStats(token)
                ]);

            } catch (error) {
                console.error('Error loading dashboard data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllData();
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
            if (response.data?._id) {
                sessionStorage.setItem('user_id', response.data._id);
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
        }
    };

    const fetchMonthlyUsage = async (token) => {
        try {
            const response = await axios.get('/api/users/monthly-usage', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMonthlyUsage(response.data || { tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
        } catch (error) {
            console.error('Failed to fetch monthly usage:', error);
        }
    };

    const fetchTickets = async (token) => {
        try {
            const response = await axios.get('/api/tickets/tickets', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTickets(response.data.tickets || []);
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    const fetchTicketStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('API response:', response.data);
            setTicketStats(response.data || {
                total_tickets: 0,
                ai_resolved_tickets: 0,
                assigned_tickets: 0,
                open_tickets: 0,
                pending_tickets: 0,
                closed_tickets: 0,
                resolved_tickets: 0,
                automation_ratio: 0,
                automated_tickets: 0,
                remaining_tickets: 0,
                waiting_carrier_response: 0,
                new_tickets: 0,
                affected_tickets: 0,
                waiting_client_response: 0,
                user_type: ''
            });
        } catch (error) {
            console.error('Failed to fetch ticket stats:', error);
        }
    };

    const fetchOrders = async (token) => {
        try {
            const response = await axios.get('/api/shopify/get_orders', {
                headers: { Authorization: `Bearer ${token}` },
                params: { limit: 5 }
            });
            setOrders(response.data.orders);
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        } 
    };

    const fetchReturns = async (token) => {
        try {
            const response = await axios.get('/api/webhook/returns', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setReturns(response.data.returns.slice(0, 5));
        } catch (error) {
            console.error('Failed to fetch returns:', error);
        }
    };

    const fetchAttentionProducts = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('/api/shopify/get_products_needing_attention', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAttentionProducts(response.data.products.slice(0, 5)); // Only get top 5
        } catch (error) {
            console.error('Failed to fetch attention products:', error);
        }
    };

    const fetchTagsStatistics = async (token) => {
        try {
            const response = await axios.get('/api/tickets/tickets-tags-stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTagsStats(response.data);
        } catch (error) {
            console.error('Error fetching tags statistics:', error);
        }
    };

    const fetchClientStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/client-stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClientStats(response.data);
        } catch (error) {
            console.error('Failed to fetch client stats:', error);
        }
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/status`, null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            navigate(`/tickets/${ticketId}/messages`);
        } catch (error) {
            console.error('Failed to update ticket status:', error);
        }
    };

    const toggleCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    const formatReason = (reason) => {
        const reasons = {
            out_of_stock: 'Out of Stock',
            low_stock: 'Low Stock',
            overstock: 'Overstock',
            low_sales_velocity: 'Low Sales',
            high_discount: 'High Discount',
            missing_handle: 'SEO Issue',
            thin_content: 'Thin Content',
            no_images: 'No Images',
            few_images: 'Few Images',
            no_tags: 'No Tags',
            single_variant: 'Single Variant'
        };
        return reasons[reason] || reason;
    };

    const tagsChartData = tagsStats?.tags_statistics ? {
        labels: tagsStats.tags_statistics.map(item => item._id),
        datasets: [{
            data: tagsStats.tags_statistics.map(item => item.percentage),
            backgroundColor: [
                '#00ff9d', '#00ffff', '#ff00ff', 
                '#8000ff', '#ffff00', '#ff0000', '#0099ff'
            ],
        }],
    } : null;

    const toggleStatsCard = () => {
        setIsStatsCardCollapsed(!isStatsCardCollapsed);
    };

    if (isLoading) {
        return (
            <div className="una-dashboard-loading-screen">
                <div className="una-loading-content">
                    <div className="una-loading-icons">
                        <FaTicketAlt className="icon-primary" />
                        <FaShoppingCart className="icon-secondary" />
                        <FaChartLine className="icon-tertiary" />
                    </div>
                    <h2>{t('loadingDashboard')}</h2>
                    <div className="una-loading-progress">
                        <div className="una-progress-bar">
                            <div className="una-progress-glow"></div>
                        </div>
                    </div>
                    <div className="una-loading-metrics">
                        <div className="una-metric-item">
                            <FaTicketAlt />
                            <span>{t('loadingTickets')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaShoppingCart />
                            <span>{t('loadingOrders')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaChartLine />
                            <span>{t('loadingStats')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaBoxOpen />
                            <span>{t('loadingReturns')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="una-dashboard-container">
            <div className="una-dashboard-header">
                <div className="una-dashboard-stats-grid">
                    <div className={`una-stat-card full-width ${isStatsCardCollapsed ? 'collapsed' : ''}`}>
                        <div className="una-stats-content">
                            <div className="una-stats-metrics">
                                <div className="una-metric-group">
                                    <div className="una-stats-section">
                                        <div className="una-stats-grid">
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.new_tickets}</span>
                                                    <span className="una-metric-label">{t('newTickets')}</span>
                                                </div>
                                            </div>
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaRobot />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.automated_tickets}</span>
                                                    <span className="una-metric-label">{t('automatedTickets')}</span>
                                                </div>
                                            </div>
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.waiting_client_response}</span>
                                                    <span className="una-metric-label">{t('waitingClientResponse')}</span>
                                                </div>
                                            </div>
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.waiting_carrier_response}</span>
                                                    <span className="una-metric-label">{t('waitingCarrierResponse')}</span>
                                                </div>
                                            </div>
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.resolved_tickets}</span>
                                                    <span className="una-metric-label">{t('resolvedTickets')}</span>
                                                </div>
                                            </div>
                                            <div className="una-metric-item">
                                                <div className="una-metric-icon">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value">{ticketStats.closed_tickets}</span>
                                                    <span className="una-metric-label">{t('closedTickets')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {user?.role === 'admin' && (
                                        <div className="una-stats-section">
                                            <div className="una-stats-grid">
                                                {clientStats.most_active_client && (
                                                    <>
                                                        <div className="una-metric-item">
                                                            <div className="una-metric-icon">
                                                                <FaUsers />
                                                            </div>
                                                            <div className="una-metric-details">
                                                                <span className="una-metric-value">
                                                                    {clientStats.most_active_client.client_name}
                                                                </span>
                                                                <span className="una-metric-label">{t('mostActiveClient')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="una-metric-item">
                                                            <div className="una-metric-icon">
                                                                <FaTicketAlt />
                                                            </div>
                                                            <div className="una-metric-details">
                                                                <span className="una-metric-value">{clientStats.most_active_client.total_tickets}</span>
                                                                <span className="una-metric-label">{t('ticketsFromTopClient')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="una-metric-item">
                                                            <div className="una-metric-icon">
                                                                <FaSmile />
                                                            </div>
                                                            <div className="una-metric-details">
                                                                <span className="una-metric-value">
                                                                    {clientStats.most_active_client.avg_sentiment}
                                                                </span>
                                                                <span className="una-metric-label">{t('topClientSentiment')}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                
                                {tagsChartData && (
                                    <div className="una-tags-chart">
                                        <Pie
                                            data={tagsChartData}
                                            options={{
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: {
                                                        position: 'right',
                                                        labels: {
                                                            color: '#fff',
                                                            padding: 10,
                                                            font: {
                                                                size: 11
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        callbacks: {
                                                            label: (context) => {
                                                                const label = context.label || '';
                                                                const value = context.raw || 0;
                                                                return `${label}: ${value.toFixed(1)}%`;
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="una-collapse-button" onClick={toggleStatsCard}>
                            <span className={`una-collapse-icon ${isStatsCardCollapsed ? 'collapsed' : ''}`}>
                                ▼
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="una-dashboard-main">
                {user?.edn_api_account ? (
                    // Display TicketTableDashboard for edn_api_account users
                    <TicketTableDashboard 
                        tickets={tickets} 
                        user={user}
                        onTicketClick={(ticketId) => navigate(`/tickets/${ticketId}/messages`)}
                    />
                ) : (
                    // Original dashboard layout for non-edn_api_account users
                    <div className="una-dashboard-grid">
                        {/* Tickets Section */}
                        <div className="una-dashboard-card">
                            <div className="una-card-header">
                                <FaTicketAlt className="tickets-icon" />
                                <h3>{t('activeTickets')}</h3>
                                <button onClick={() => navigate('/tickets')} className="una-view-all">
                                    <FaExternalLinkAlt />
                                </button>
                            </div>
                            <div className="una-card-content">
                                {tickets
                                    .filter(t => !['resolved', 'closed', 'ai_resolved'].includes(t.status))
                                    .slice(0, 10)
                                    .map(ticket => (
                                        <div key={ticket._id} className="una-list-item" onClick={() => navigate(`/tickets/${ticket._id}/messages`)}>
                                            <div className="una-ticket-info">
                                                <span className="una-ticket-subject">{ticket.subject}</span>
                                                <span className={`una-status-badge ${ticket.status}`}>
                                                    {ticket.status}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>

                        {/* Orders & Returns Section - Only show if user doesn't have edn_api_account */}
                        <div className="una-activity-section">
                            {/* Orders Card */}
                            <div className="una-dashboard-card">
                                <div className="una-card-header">
                                    <FaShoppingCart className="orders-icon" />
                                    <h3>{t('recentOrders')}</h3>
                                    <button onClick={() => navigate('/orders')} className="una-view-all">
                                        <FaExternalLinkAlt />
                                    </button>
                                </div>
                                <div className="una-card-content">
                                    {orders.map(order => (
                                        <div key={order.id} className="una-list-item" onClick={() => navigate(`/order/${order.id}`)}>
                                            <div className="una-order-info">
                                                <div className="una-order-main">
                                                    <span className="una-order-number">#{order.order_number}</span>
                                                    <span className="una-order-customer">{order.customer?.name}</span>
                                                </div>
                                            </div>
                                            <div className="una-order-details">
                                                <span className="una-order-price">${order.total_price}</span>
                                                <span className={`una-status-badge ${order.financial_status}`}>
                                                    {order.financial_status}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Returns Card */}
                            <div className="una-dashboard-card">
                                <div className="una-card-header">
                                    <FaBoxOpen className="returns-icon" />
                                    <h3>{t('recentReturns')}</h3>
                                    <button onClick={() => navigate('/returns')} className="una-view-all">
                                        <FaExternalLinkAlt />
                                    </button>
                                </div>
                                <div className="una-card-content">
                                    {returns.slice(0, 10).map(returnItem => (
                                        <div 
                                            key={returnItem._id} 
                                            className="una-activity-item"
                                            onClick={() => navigate(`/return/${returnItem._id}`)}
                                            role="button"
                                            tabIndex={0}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    navigate(`/return/${returnItem._id}`);
                                                }
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className="una-activity-item-main">
                                                <div className="una-activity-item-content">
                                                    <div className="una-activity-item-title">
                                                        {returnItem.items?.[0]?.title || 'No title'}
                                                    </div>
                                                    <div className="una-activity-item-subtitle">
                                                        Order #{returnItem.order_id} • {returnItem.tracking_number}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="una-activity-item-side">
                                                <span className="una-activity-item-price">
                                                    €{(returnItem.items?.[0]?.price || 0).toFixed(2)}
                                                </span>
                                                <span className={`una-status-badge ${returnItem.status.replace('_', ' ').replace(' ', '-').toLowerCase()}`}>
                                                    {returnItem.status.replace('_', ' ')}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Attention Products Section - Only show if user doesn't have edn_api_account */}
            {!user?.edn_api_account && (
                <div className="una-attention-section">
                    <div className="una-dashboard-card">
                        <div className="una-card-header">
                            <FaExclamationCircle />
                            <h3>{t('productsNeedingAttention')}</h3>
                            <button onClick={() => navigate('/statistics')} className="una-view-all">
                                <FaExternalLinkAlt />
                            </button>
                        </div>
                        <div className="una-card-content">
                            {attentionProducts.map(product => (
                                <div 
                                    key={product.id} 
                                    className="una-list-item"
                                    onClick={() => navigate(`/product/${product.id}`)}
                                >
                                    <div className="una-product-info">
                                        <span className="una-product-title">{product.title}</span>
                                        <div className="una-product-tags">
                                            {product.health_status.reasons.map((reason, index) => (
                                                <span key={index} className={`una-reason-tag ${reason}`}>
                                                    {formatReason(reason)}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="una-product-metrics">
                                        {product.health_status.metrics.sales_30_days !== undefined && (
                                            <span className="una-metric">
                                                <FaShoppingCart />
                                                {product.health_status.metrics.sales_30_days}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;