import React from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import './PaymentResult.css';

const PaymentFailure = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error') || 'An error occurred during payment';

  return (
    <div className="payment-result error">
      <div className="payment-result-content">
        <h1>Payment Failed</h1>
        <p>{error}</p>
        <p>Please try again or contact support if the problem persists.</p>
        <Link to="/" className="payment-result-button">Return to Home</Link>
      </div>
    </div>
  );
};

export default PaymentFailure;