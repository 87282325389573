// src/components/AIAssistantManagement.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AIAssistantManagement.css';
import CreateForm from './CreateForm';
import KnowledgeBoard from './KnowledgeBoard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaRobot, FaPen, FaCode, FaTrash, FaPlay, FaPlus, FaBook, FaWpforms, FaFilePdf, FaToggleOn, FaToggleOff, FaTools, FaCheck } from 'react-icons/fa';
import CreatePDFFormFiller from './CreatePDFFormFiller';
import OpenAPISchemeManagement from './OpenAPISchemeManagement';
import ChatbotTest from './ChatbotTest';

const AIAssistantManagement = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined);
  const [aiAssistants, setAIAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [name, setName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [questions, setQuestions] = useState(['', '', '', '']);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [promptPopupOpen, setPromptPopupOpen] = useState(false);
  const [promptInput, setPromptInput] = useState('');
  const [promptMode, setPromptMode] = useState('edit');
  const [activeTab, setActiveTab] = useState('knowledge');
  const [availableTools, setAvailableTools] = useState([]);
  const [selectedToolIds, setSelectedToolIds] = useState([]);
  const navigate = useNavigate();
  const [automation, setAutomation] = useState(false);
  const [showAgentSelector, setShowAgentSelector] = useState(false);
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedPrompt, setEditedPrompt] = useState('');
  const [editedToolIds, setEditedToolIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPromptModal, setShowPromptModal] = useState(false);

  const questionTitles = [
    t('aiAssistant.question1'),
    t('aiAssistant.question2'),
    t('aiAssistant.question3'),
    t('aiAssistant.question4'),
  ];

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  const fetchData = async (token) => {
    try {
      setIsLoading(true);
      const [assistantsResponse, toolsResponse] = await Promise.all([
        axios.get('/api/ai-assistants', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/tools', {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);

      const assistants = assistantsResponse.data;
      setAIAssistants(assistants);
      
      // Find and set the running agent
      const runningAgent = assistants.find(assistant => assistant.is_running);
      if (runningAgent) {
        setSelectedAssistant(runningAgent);
      }
      
      setAvailableTools(toolsResponse.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchData(token);
      setAutomation(user.automation || false);
    }
  }, [user]);

  useEffect(() => {
    if (selectedAssistant) {
      setName(selectedAssistant.name);
      setPrompt(selectedAssistant.prompt);
      setSelectedToolIds(selectedAssistant.selected_tool_ids || []);
      // Set edited values
      setEditedName(selectedAssistant.name);
      setEditedPrompt(selectedAssistant.prompt);
      setEditedToolIds(selectedAssistant.selected_tool_ids || []);
    }
  }, [selectedAssistant]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUser(null);
    }
  };

  const handleDeleteAIAssistant = async (assistantId) => {
    if (!assistantId) {
      console.error('Assistant ID is undefined');
      alert('Unable to delete AI assistant: Missing ID');
      return;
    }
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.delete(`/api/ai-assistants/${assistantId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('AI assistant deleted successfully');
      fetchData(token);
    } catch (error) {
      console.error('Failed to delete AI assistant:', error);
      alert('Failed to delete AI assistant.');
    }
  };

  const handleCreateAIAssistant = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post(
        '/api/ai-assistants',
        { name, prompt, selected_tool_ids: selectedToolIds },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('AI assistant created:', response.data);
      fetchData(token);
      resetForm();
      alert('AI assistant created successfully');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error('Failed to create AI assistant:', error.response.data.detail);
        alert(error.response.data.detail);
      } else {
        console.error('Failed to create AI assistant:', error);
        alert('Failed to create AI assistant.');
      }
    }
  };

  const handleUpdateAIAssistant = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/ai-assistants/${selectedAssistant._id}`,
        { 
          name: editedName,
          prompt: editedPrompt,
          selected_tool_ids: editedToolIds 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Update the local state with edited values
      setName(editedName);
      setPrompt(editedPrompt);
      setSelectedToolIds(editedToolIds);
      setIsEditing(false);
      fetchData(token);
      alert(t('aiAssistant.updateSuccess'));
    } catch (error) {
      console.error('Failed to update AI assistant:', error);
      alert(t('aiAssistant.updateError'));
    }
  };

  const handleSelectAssistant = (assistant) => {
    if (selectedAssistant && selectedAssistant._id === assistant._id) {
      resetForm();
    } else {
      setSelectedAssistant(assistant);
      setName(assistant.name);
      setPrompt(assistant.prompt);
      setSelectedToolIds(assistant.selected_tool_ids || []);
    }
  };

  const handleSetRunning = async (assistantId) => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/ai-assistants/${assistantId}/running`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchData(token);
    } catch (error) {
      console.error('Failed to set AI assistant as running:', error);
      alert('Failed to set AI assistant as running.');
    }
  };

  const resetForm = () => {
    setSelectedAssistant(null);
    setName('');
    setPrompt('');
    setSelectedToolIds([]);
    setPromptInput('');
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
    setCurrentQuestionIndex(0);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setCurrentQuestionIndex(0);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePromptPopupOpen = () => {
    setPromptInput(prompt);
    setPromptMode('edit');
    setPromptPopupOpen(true);
  };

  const handlePromptPopupClose = () => {
    setPromptPopupOpen(false);
    setPromptMode('edit');
  };

  const handlePromptSave = () => {
    setPrompt(promptInput);
    handlePromptPopupClose();
  };

  const handleSwitchToGenerate = () => {
    setPromptMode('generate');
    setQuestions(['', '', '', '']);
  };

  const handleGenerateInstructions = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.post(
        '/api/ai-assistants/generate-instructions',
        { questions },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPromptInput(response.data.instructions);
      setPromptMode('edit');
    } catch (error) {
      console.error('Failed to generate instructions:', error);
      alert('Failed to generate instructions.');
    }
  };

  const handlePromptChange = (e) => {
    setPromptInput(e.target.value);
  };

  const getPromptPreview = (text) => {
    const maxLength = 50;
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'knowledge':
        return <KnowledgeBoard />;
      case 'form':
        return <CreateForm />;
      case 'pdfFormFiller':
        return <CreatePDFFormFiller />;
      case 'codeSnippets':
        return <OpenAPISchemeManagement />;
      case 'chatbotTest':
        return <ChatbotTest />;
      default:
        return null;
    }
  };

  const handleAutomationToggle = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        '/api/users/automation',
        { automation: !automation },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAutomation(!automation);
    } catch (error) {
      console.error('Failed to toggle automation:', error);
      alert('Failed to toggle automation.');
    }
  };

  const handleAgentCircleClick = () => {
    setShowAgentSelector(true);
  };

  const getToolTranslation = (toolName) => {
    const toolTranslations = {
      'retrieve_knowledge': t('tools.retrieveKnowledge'),
      'assign_to_employee': t('tools.assignToEmployee'),
      'resolve_ticket': t('tools.resolveTicket'),
      'Close_ticket': t('tools.closeTicket'),
      'get_tracking_info': t('tools.getTrackingInfo'),
      'Retrieve_customer_order_by_reference_and_email': t('tools.retrieveOrder'),
      'Update_customer_shipping_address': t('tools.updateShippingAddress'),
      'find_relay_points': t('tools.findRelayPoints'),
      'create_return_with_edn_label': t('tools.createReturn'),
      'carriers_conversation': t('tools.carriersConversation'),
      'Retour': t('tools.return'),
      'fill_form_fields': t('tools.fillPDF'),
      'getOrderById': t('tools.getOrderById'),
      'carriers_conversation_update': t('tools.carriersConversationUpdate'),
      'order_status': t('tools.orderStatus'),
      'Request_return_order': t('tools.Request_return_order'),
      'Retrieve_customer_order_by_ID': t('tools.Retrieve_customer_order_by_ID'),
      'Request_exchange_shopify': t('tools.Request_exchange_shopify'),
      'Get_all_product_variants': t('tools.Get_all_product_variants')
    };
    return toolTranslations[toolName] || toolName;
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    setEditedName(name);
    setEditedPrompt(prompt);
    setEditedToolIds(selectedToolIds);
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setEditedName(name);
    setEditedPrompt(prompt);
    setEditedToolIds(selectedToolIds);
  };

  const handlePromptClick = () => {
    setShowPromptModal(true);
  };

  const handleClosePromptModal = () => {
    setShowPromptModal(false);
  };

  if (user === undefined || isLoading) {
    return (
      <div className="una-order-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-icons">
            <FaRobot className="icon-primary" />
            <FaTools className="icon-secondary" />
            <FaCode className="icon-tertiary" />
          </div>
          <h2>Loading AI Assistants</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaRobot />
              <span>Fetching Assistants</span>
            </div>
            <div className="una-metric-item">
              <FaTools />
              <span>Loading Tools</span>
            </div>
            <div className="una-metric-item">
              <FaCode />
              <span>Processing Configurations</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (user === null) {
    return null;
  }

  return (
    <div className="ai-assistant-container">
      <div className="panels-wrapper">
        <div className="left-panel">
          <div className="agent-selector" onClick={handleAgentCircleClick}>
            <div className="agent-circle">
              <FaRobot />
            </div>
            <div className="agent-info">
              <span className="agent-name">
                {selectedAssistant ? selectedAssistant.name : t('aiAssistant.selectAgent')}
              </span>
              {selectedAssistant?.is_running && (
                <span className="running-status">{t('aiAssistant.running')}</span>
              )}
            </div>
          </div>

          {selectedAssistant && (
            <div className="agent-details">
              <div className="agent-details-header">
                <h3>{t('aiAssistant.agentDetails')}</h3>
                {!isEditing && (
                  <button 
                    className="edit-button"
                    onClick={handleStartEditing}
                  >
                    <FaPen /> {t('aiAssistant.edit')}
                  </button>
                )}
              </div>
              
              {isEditing && (
                <div className="form-group">
                  <label htmlFor="name">{t('aiAssistant.name')}:</label>
                  <input
                    type="text"
                    id="name"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    className="ai-agent-input"
                  />
                </div>
              )}
              <div className="ai-agent-tools-section">
                <h4>{t('aiAssistant.tools')}</h4>
                <div className="ai-agent-tools-grid">
                  {availableTools.map((tool) => (
                    <div key={tool.id} className="ai-agent-tool-item">
                      {isEditing ? (
                        <input
                          type="checkbox"
                          id={`tool-${tool.id}`}
                          checked={editedToolIds.includes(tool.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setEditedToolIds([...editedToolIds, tool.id]);
                            } else {
                              setEditedToolIds(editedToolIds.filter(id => id !== tool.id));
                            }
                          }}
                        />
                      ) : (
                        <div className="tool-status">
                          {selectedToolIds.includes(tool.id) && <FaCheck className="tool-check" />}
                        </div>
                      )}
                      <label htmlFor={`tool-${tool.id}`}>{getToolTranslation(tool.name)}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="prompt">{t('aiAssistant.prompt')}:</label>
                <div 
                  className="ai-agent-value prompt-value clickable" 
                  onClick={handlePromptClick}
                >
                  {getPromptPreview(prompt)}
                </div>
              </div>


              {isEditing && (
                <div className="modal-actions">
                  <button className="primary-button" onClick={handleUpdateAIAssistant}>
                    {t('aiAssistant.save')}
                  </button>
                  <button className="secondary-button" onClick={handleCancelEditing}>
                    {t('aiAssistant.cancel')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="right-panel">
          <div className="tabs-section">
            <div className="tabs">
              <button
                className={`tab-button ${activeTab === 'knowledge' ? 'active' : ''}`}
                onClick={() => setActiveTab('knowledge')}
              >
                <FaBook /> {t('aiAssistant.knowledgeBoard')}
              </button>
              <button
                className={`tab-button ${activeTab === 'form' ? 'active' : ''}`}
                onClick={() => setActiveTab('form')}
              >
                <FaWpforms /> {t('aiAssistant.createForm')}
              </button>
              <button
                className={`tab-button ${activeTab === 'pdfFormFiller' ? 'active' : ''}`}
                onClick={() => setActiveTab('pdfFormFiller')}
              >
                <FaFilePdf /> {t('aiAssistant.createPDFFormFiller')}
              </button>
              <button
                className={`tab-button ${activeTab === 'codeSnippets' ? 'active' : ''}`}
                onClick={() => setActiveTab('codeSnippets')}
              >
                <FaCode /> {t('codeSnippets.tabTitle')}
              </button>
            </div>
            <div className="tab-content">
              {renderTabContent()}
            </div>
          </div>
        </div>
      </div>

      <div className="chatbot-test-container">
        <ChatbotTest />
      </div>

      {/* Agent Selector Modal */}
      {showAgentSelector && (
        <div className="modalai">
          <div className="modal-content agent-selector-modal">
            <h3>{t('aiAssistant.selectOrCreate')}</h3>
            <div className="agent-list">
              {aiAssistants.map((assistant) => (
                <div
                  key={assistant._id}
                  className={`agent-item ${assistant.is_running ? 'running' : ''}`}
                >
                  <div 
                    className="agent-item-content"
                    onClick={() => {
                      handleSelectAssistant(assistant);
                      setShowAgentSelector(false);
                    }}
                  >
                    <div className="agent-circle small">
                      <FaRobot />
                    </div>
                    <div className="agent-item-info">
                      <span className="agent-item-name">{assistant.name}</span>
                    </div>
                  </div>
                  <button
                    className={`run-button ${assistant.is_running ? 'running' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetRunning(assistant._id);
                    }}
                  >
                    <FaPlay /> {assistant.is_running ? t('aiAssistant.running') : t('aiAssistant.run')}
                  </button>
                </div>
              ))}
            </div>
            <div className="modal-actions">
              <button
                className="primary-button"
                onClick={() => {
                  setShowAgentSelector(false);
                  setShowCreateAgentModal(true);
                }}
              >
                <FaPlus /> {t('aiAssistant.createNew')}
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowAgentSelector(false)}
              >
                {t('aiAssistant.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Create Agent Modal */}
      {showCreateAgentModal && (
        <div className="ai-agent-modal">
          <div className="ai-agent-modal-content">
            <h3>{t('aiAssistant.createAssistant')}</h3>
            <div className="form-group">
              <label htmlFor="name">{t('aiAssistant.name')}:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="prompt">{t('aiAssistant.prompt')}:</label>
              <textarea
                id="prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                rows="4"
                className="ai-agent-textarea"
              />
            </div>
            <div className="ai-agent-tools-section">
              <h4>{t('aiAssistant.selectTools')}</h4>
              <div className="ai-agent-tools-grid">
                {availableTools.map((tool) => (
                  <div key={tool.id} className="ai-agent-tool-item">
                    <input
                      type="checkbox"
                      id={`tool-${tool.id}`}
                      checked={selectedToolIds.includes(tool.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedToolIds([...selectedToolIds, tool.id]);
                        } else {
                          setSelectedToolIds(selectedToolIds.filter(id => id !== tool.id));
                        }
                      }}
                    />
                    <label htmlFor={`tool-${tool.id}`}>{getToolTranslation(tool.name)}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-actions">
              <button
                className="primary-button"
                onClick={() => {
                  handleCreateAIAssistant();
                  setShowCreateAgentModal(false);
                }}
              >
                {t('aiAssistant.create')}
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowCreateAgentModal(false)}
              >
                {t('aiAssistant.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}

      {showPromptModal && (
        <div className="modalai">
          <div className="modal-content prompt-modal">
            <h3>{t('aiAssistant.prompt')}</h3>
            {isEditing ? (
              <textarea
                value={editedPrompt}
                onChange={(e) => setEditedPrompt(e.target.value)}
                rows="15"
                className="ai-agent-textarea"
              />
            ) : (
              <div className="prompt-preview-content">
                {prompt}
              </div>
            )}
            <div className="modal-actions">
              {isEditing && (
                <button 
                  className="primary-button" 
                  onClick={handleUpdateAIAssistant}
                >
                  {t('aiAssistant.save')}
                </button>
              )}
              <button 
                className="secondary-button" 
                onClick={handleClosePromptModal}
              >
                {t('aiAssistant.close')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default AIAssistantManagement;
