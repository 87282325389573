import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBoxOpen, FaTruck, FaShippingFast, FaBox, FaBarcode, FaClipboardList } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './ManageReturns.css';

const ManageReturns = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [returns, setReturns] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReturns = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/webhook/returns', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setReturns(response.data.returns);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReturns();
  }, []);

  const formatDate = (isoString) => {
    return new Date(isoString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleReturnClick = (returnId) => {
    navigate(`/return/${returnId}`);
  };

  if (error) return <div className="una-returns-error">{t('returns.error')}: {error}</div>;

  return (
    <div className="una-returns-container">
      <div className="una-returns-header">
        <div className="una-returns-title">
          <FaBoxOpen />
          <h2>{t('returns.title')}</h2>
        </div>
      </div>

      {isLoading ? (
        <div className="una-returns-loading-screen">
          <div className="una-loading-content">
            <div className="una-loading-icons">
              <FaBoxOpen className="icon-primary" />
              <FaTruck className="icon-secondary" />
              <FaShippingFast className="icon-tertiary" />
            </div>
            <h2>{t('returns.loadingTitle')}</h2>
            <div className="una-loading-progress">
              <div className="una-progress-bar">
                <div className="una-progress-glow"></div>
              </div>
            </div>
            <div className="una-loading-metrics">
              <div className="una-metric-item">
                <FaBox />
                <span>{t('returns.fetchingRequests')}</span>
              </div>
              <div className="una-metric-item">
                <FaBarcode />
                <span>{t('returns.processingTracking')}</span>
              </div>
              <div className="una-metric-item">
                <FaShippingFast />
                <span>{t('returns.loadingLabels')}</span>
              </div>
              <div className="una-metric-item">
                <FaClipboardList />
                <span>{t('returns.processingStatus')}</span>
              </div>
            </div>
          </div>
        </div>
      ) : !returns.length ? (
        <div className="una-returns-empty-state">
          <FaBoxOpen className="empty-icon" />
          <p>{t('returns.noReturnsFound')}</p>
        </div>
      ) : (
        <div className="una-returns-grid">
          {returns.map((returnItem) => (
            <div 
              key={returnItem._id} 
              className="una-return-card"
              onClick={() => handleReturnClick(returnItem._id)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleReturnClick(returnItem._id);
                }
              }}
            >
              <div className="una-return-card-header">
                <span className={`una-return-status una-return-status-${returnItem.status.toLowerCase()}`}>
                  {returnItem.status}
                </span>
                <span className="una-return-date">{formatDate(returnItem.created_at)}</span>
              </div>
              
              <div className="una-return-card-content">
                {returnItem.items.map((item, index) => (
                  <div key={index} className="una-return-item">
                    <div className="una-return-item-details">
                      <h3>{item.title}</h3>
                      <p>{t('returns.quantity')}: {item.quantity}</p>
                      <p>{t('returns.price')}: €{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>

              {returnItem.tracking_number && (
                <div className="una-return-tracking">
                  <p>{t('returns.trackingNumber')}: {returnItem.tracking_number}</p>
                  {returnItem.label_url && (
                    <a 
                      href={returnItem.label_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="una-return-label-btn"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {t('returns.downloadLabel')}
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageReturns;