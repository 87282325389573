import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import axios from 'axios';
import './PaymentResult.css';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const payment_intent = searchParams.get('payment_intent');
    if (payment_intent) {
      const fetchOrderDetails = async () => {
        try {
          const response = await axios.get(`/api/shopify/public/payment/${payment_intent}/status`);
          setOrderDetails(response.data);
        } catch (err) {
          setError(err.response?.data?.detail || 'Failed to load order details');
        } finally {
          setLoading(false);
        }
      };
      fetchOrderDetails();
    }
  }, [searchParams]);

  if (loading) return <div className="payment-result loading">Loading...</div>;
  if (error) return <div className="payment-result error">{error}</div>;

  return (
    <div className="payment-result success">
      <div className="payment-result-content">
        <h1>Payment Successful!</h1>
        {orderDetails && (
          <>
            <p>Order number: {orderDetails.order_number}</p>
            <p>Thank you for your purchase. You will receive a confirmation email shortly.</p>
          </>
        )}
        <Link to="/" className="payment-result-button">Return to Home</Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;