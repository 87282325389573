import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaCalendar, FaList, FaPlus, FaTruck, FaArrowLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './FastLive.css';

const FastLive = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listName, setListName] = useState('');
  const [description, setDescription] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [platform, setPlatform] = useState('');
  const [shippingFee, setShippingFee] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data.products);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProducts(prev => {
      const exists = prev.find(p => p.product_id === product.id);
      if (exists) {
        return prev.filter(p => p.product_id !== product.id);
      }
      return [...prev, { 
        product_id: product.id,
        reference: product.handle || product.id
      }];
    });
  };

  const handleCreateList = async (e) => {
    e.preventDefault();
    if (!listName || selectedProducts.length === 0) {
      alert(t('fastLive.validation.provideNameAndProducts'));
      return;
    }

    try {
      const token = sessionStorage.getItem('access_token');
      const payload = {
        name: listName,
        products: selectedProducts,
        scheduled_date: scheduledDate || null,
        platform: platform || null,
        description: description || null,
        shipping_fee: parseFloat(shippingFee) || 0
      };

      const response = await axios.post(
        '/api/shopify/create_live_products_list',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      alert(t('fastLive.success.listCreated'));
      navigate('/live-list');
    } catch (err) {
      setError(t('fastLive.errors.createList', { error: err.message }));
    }
  };

  if (error) return <div className="una-error">{t('common.error')}: {error}</div>;
  if (isLoading) return <div className="una-loading">{t('common.loading')}</div>;

  return (
    <div className="una-fast-live-container">
      <div className="una-back-button" onClick={() => navigate('/live-list')}>
        <FaArrowLeft /> {t('common.backToLists')}
      </div>
      <form onSubmit={handleCreateList} className="una-live-form">
        <div className="una-form-header">
          <h2><FaList /> {t('fastLive.title')}</h2>
        </div>

        <div className="una-form-group">
          <input
            type="text"
            placeholder={t('fastLive.form.listName')}
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            required
            className="una-input"
          />
        </div>

        <div className="una-form-group">
          <textarea
            placeholder={t('fastLive.form.description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="una-textarea"
          />
        </div>

        <div className="una-form-row">
          <div className="una-form-group">
            <div className="una-input-icon">
              <FaCalendar />
              <input
                type="datetime-local"
                value={scheduledDate}
                onChange={(e) => setScheduledDate(e.target.value)}
                className="una-input"
              />
            </div>
          </div>

          <div className="una-form-group">
            <select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              className="una-select"
            >
              <option value="">{t('fastLive.form.selectPlatform')}</option>
              <option value="instagram">Instagram</option>
              <option value="tiktok">TikTok</option>
              <option value="facebook">Facebook</option>
            </select>
          </div>
        </div>

        <div className="una-form-group">
          <div className="una-input-icon">
            <FaTruck />
            <input
              type="number"
              placeholder={t('fastLive.form.shippingFee')}
              value={shippingFee}
              onChange={(e) => setShippingFee(e.target.value)}
              min="0"
              step="0.01"
              className="una-input"
            />
          </div>
        </div>

        <div className="una-products-grid">
          {products.map((product) => (
            <div
              key={product.id}
              className={`una-product-card ${
                selectedProducts.some(p => p.product_id === product.id) ? 'selected' : ''
              }`}
              onClick={() => handleProductSelect(product)}
            >
              {product.images?.[0] && (
                <img
                  src={product.images[0].src}
                  alt={product.title}
                  className="una-product-image"
                />
              )}
              <div className="una-product-info">
                <h3>{product.title}</h3>
                <p>{product.product_type}</p>
              </div>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="una-submit-button"
          disabled={!listName || selectedProducts.length === 0}
        >
          <FaPlus /> {t('fastLive.form.createList')}
        </button>
      </form>
    </div>
  );
};

export default FastLive;