import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './LiveListDetails.css';

const LiveListDetails = () => {
  const { t } = useTranslation();
  const { listId } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [newCustomers, setNewCustomers] = useState([]);
  const [ordersSummary, setOrdersSummary] = useState(null);
  const [customersSummary, setCustomersSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCompleting, setIsCompleting] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(null);
  const [clickCollectSearch, setClickCollectSearch] = useState('');
  const [completingOrders, setCompletingOrders] = useState(new Set());
  const [orderStatuses, setOrderStatuses] = useState({});

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const [ordersResponse, customersResponse] = await Promise.all([
        axios.get(`/api/shopify/live_products_list/${listId}/orders`, { headers }),
        axios.get(`/api/shopify/live_products_list/${listId}/new_customers`, { headers })
      ]);

      setOrders(ordersResponse.data.orders);
      setOrdersSummary(ordersResponse.data.summary);
      setNewCustomers(customersResponse.data.customers);
      setCustomersSummary(customersResponse.data.summary);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [listId]);

  const handleCompleteOrders = async () => {
    try {
      setIsCompleting(true);
      const token = sessionStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `/api/shopify/live_products_list/${listId}/complete_orders`,
        {},
        { headers }
      );

      setCompletionStatus({
        success: true,
        message: t('liveListDetails.completionSuccess', {
          completed: response.data.total_completed,
          failed: response.data.total_failed ? t('liveListDetails.failedCount', { count: response.data.total_failed }) : ''
        })
      });

      fetchData();
    } catch (err) {
      setCompletionStatus({
        success: false,
        message: t('liveListDetails.completionError', { error: err.message })
      });
    } finally {
      setIsCompleting(false);
    }
  };

  const handleCompleteSingleOrder = async (orderId) => {
    try {
      setCompletingOrders(prev => new Set([...prev, orderId]));
      const token = sessionStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `/api/shopify/live_products_list/${listId}/complete_order/${orderId}`,
        {},
        { headers }
      );

      setOrderStatuses(prev => ({
        ...prev,
        [orderId]: {
          success: true,
          message: response.data.status === 'completed' 
            ? t('liveListDetails.orderCompletionSuccess')
            : t('liveListDetails.orderAlreadyCompleted')
        }
      }));

      if (response.data.live_completed) {
        fetchData();
      }
    } catch (err) {
      setOrderStatuses(prev => ({
        ...prev,
        [orderId]: {
          success: false,
          message: t('liveListDetails.orderCompletionError', { error: err.message })
        }
      }));
    } finally {
      setCompletingOrders(prev => {
        const next = new Set(prev);
        next.delete(orderId);
        return next;
      });
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const handleCustomerClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  const standardOrders = orders.filter(order => order.payment_type === 'stripe');
  const clickCollectOrders = orders.filter(order => order.payment_type === 'click_and_collect');

  const filteredClickCollectOrders = clickCollectOrders.filter(order => 
    order.draft_order_id.toString().includes(clickCollectSearch.toLowerCase()) ||
    order.customer_email.toLowerCase().includes(clickCollectSearch.toLowerCase())
  );

  if (error) return <div className="error-message">{t('common.error')}: {error}</div>;
  if (isLoading) return <div className="loading-message">{t('common.loading')}</div>;

  return (
    <div className="live-session-container">
      {/* Header Section */}
      <header className="live-session-header">
        <h1 className="live-session-title">
          {t('liveListDetails.sessionRevenue')}: €{ordersSummary.total_revenue.toFixed(2)}
        </h1>
      </header>

      {/* Summary Section */}
      <section className="session-summary">
        <div className="summary-item">
          <h3>{t('liveListDetails.totalOrders')}</h3>
          <p>{ordersSummary.total_orders}</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.averageOrderValue')}</h3>
          <p>€{ordersSummary.average_order_value.toFixed(2)}</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.totalItemsSold')}</h3>
          <p>{ordersSummary.total_items}</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.newCustomers')}</h3>
          <p>{customersSummary.total_new_customers}</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.totalCustomers')}</h3>
          <p>{customersSummary.total_customers}</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.newCustomerRate')}</h3>
          <p>{customersSummary.new_customer_percentage.toFixed(1)}%</p>
        </div>
        <div className="summary-item">
          <h3>{t('liveListDetails.averageFirstPurchase')}</h3>
          <p>€{customersSummary.average_first_purchase_value.toFixed(2)}</p>
        </div>
      </section>

      {/* Grid Container */}
      <div className="live-list-grid-container">
        {/* Standard Orders Column */}
        <section className="live-list-grid-column">
          <div className="standard-orders-header">
            <h2>{t('liveListDetails.standardOrders', { count: ordersSummary.online_payment_orders })}</h2>
            <div className="header-actions">
              <button 
                className="complete-orders-btn"
                onClick={handleCompleteOrders}
                disabled={isCompleting}
              >
                {isCompleting ? t('liveListDetails.completing') : t('liveListDetails.completeAll')}
              </button>
              {completionStatus && (
                <div className={`completion-status ${completionStatus.success ? 'success' : 'error'}`}>
                  {completionStatus.message}
                </div>
              )}
            </div>
          </div>
          <div className="orders-scroll-container">
            {standardOrders.map(order => (
              <div 
                key={order.id} 
                className="order-card"
                onClick={() => handleOrderClick(order.id)}
              >
                <div className="order-header">
                  <span className="order-id">{t('liveListDetails.orderNumber', { id: order.draft_order_id })}</span>
                  <span className="order-total">€{order.total_amount.toFixed(2)}</span>
                </div>
                <div className="order-info">
                  <p>{t('liveListDetails.customer')}: {order.customer_email}</p>
                  <p>{t('liveListDetails.date')}: {new Date(order.completed_at).toLocaleString()}</p>
                  <p>{t('liveListDetails.items')}: {order.items.length}</p>
                </div>
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item">
                      {item.image_url && (
                        <img src={item.image_url} alt={item.title} className="item-image" />
                      )}
                      <div className="item-details">
                        <p>{item.title}</p>
                        <p>{t('liveListDetails.quantity')}: {item.quantity} × €{item.price.toFixed(2)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Click & Collect Column */}
        <section className="live-list-grid-column">
          <div className="click-collect-header">
            <h2>{t('liveListDetails.clickCollectOrders', { count: ordersSummary.click_collect_orders })}</h2>
            <div className="search-container">
              <input
                type="text"
                value={clickCollectSearch}
                onChange={(e) => setClickCollectSearch(e.target.value)}
                placeholder={t('liveListDetails.searchOrdersPlaceholder')}
                className="search-input"
              />
            </div>
          </div>
          <div className="orders-scroll-container">
            {filteredClickCollectOrders.map(order => (
              <div 
                key={order.id} 
                className="order-card"
              >
                <div className="order-header">
                  <span className="order-id">{t('liveListDetails.orderNumber', { id: order.draft_order_id })}</span>
                  <span className="order-total">€{order.total_amount.toFixed(2)}</span>
                </div>
                <div className="order-info">
                  <p>{t('liveListDetails.customer')}: {order.customer_email}</p>
                  <p>{t('liveListDetails.date')}: {new Date(order.completed_at).toLocaleString()}</p>
                  <p>{t('liveListDetails.items')}: {order.items.length}</p>
                </div>
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item">
                      {item.image_url && (
                        <img src={item.image_url} alt={item.title} className="item-image" />
                      )}
                      <div className="item-details">
                        <p>{item.title}</p>
                        <p>{t('liveListDetails.quantity')}: {item.quantity} × €{item.price.toFixed(2)}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="order-actions">
                  <button 
                    className={`complete-order-btn ${completingOrders.has(order.draft_order_id) ? 'completing' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCompleteSingleOrder(order.draft_order_id);
                    }}
                    disabled={completingOrders.has(order.draft_order_id)}
                  >
                    {completingOrders.has(order.draft_order_id) 
                      ? t('liveListDetails.completing') 
                      : t('liveListDetails.complete')}
                  </button>
                  {orderStatuses[order.draft_order_id] && (
                    <div className={`order-status ${orderStatuses[order.draft_order_id].success ? 'success' : 'error'}`}>
                      {orderStatuses[order.draft_order_id].message}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* New Customers Column */}
        <section className="live-list-grid-column">
          <h2>{t('liveListDetails.newCustomers', { count: customersSummary.total_new_customers })}</h2>
          <div className="customers-scroll-container">
            {newCustomers.map(customer => (
              <div 
                key={customer.email} 
                className="customer-card"
                onClick={() => handleCustomerClick(customer.id)}
              >
                <h3>{customer.name}</h3>
                <p>{t('liveListDetails.email')}: {customer.email}</p>
                <p>{t('liveListDetails.firstPurchase')}: {new Date(customer.first_purchase_date).toLocaleString()}</p>
                <p>{t('liveListDetails.totalSpent')}: €{customer.total_spent.toFixed(2)}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiveListDetails;
