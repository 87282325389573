import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const DownloadFile = () => {
  const { filename } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Using the public folder to store downloadable files
    const downloadUrl = `/downloads/${filename}`;
    
    fetch(downloadUrl)
      .then(response => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('File not found');
      })
      .then(blob => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // This will be the suggested filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        navigate(-1);
      })
      .catch(error => {
        console.error('Download failed:', error);
        navigate(-1);
      });
  }, [filename, navigate]);

  return null;
};

export default DownloadFile;