import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css'; // Import the CSS file
import jwtDecode from 'jwt-decode';

const Welcome = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserDetails(decodedToken);
        setIsNewUser(decodedToken.is_new_user || false);
        console.log(`User Details:`, decodedToken);
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/'); // Redirect if the token is invalid
      }
    } else {
      navigate('/'); // Redirect if no token is found in URL
    }
  }, [navigate]);

  const handleRegistration = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('token', new URLSearchParams(window.location.search).get('token'));

    try {
      const response = await fetch('/api/shopify/shopify/complete-registration', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Registration failed');
      }

      const data = await response.json();
      window.location.href = 'https://unanimity.ai';
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartClick = () => {
    window.location.href = 'https://unanimity.ai';
  };

  return (
    <div className="welcome-container">
      <img src="unanimity_logo_512.png" alt="Unanimityai logo" className="unanimityai-logo" />
      <h2>Welcome to UnanimityAI</h2>
      {isNewUser ? (
        <div className="registration-form">
          <h3>Complete Your Registration</h3>
          <form onSubmit={handleRegistration}>
            <div className="form-group">
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                minLength="3"
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength="6"
              />
            </div>
            <button type="submit" className="start-button" disabled={isLoading}>
              {isLoading ? 'Registering...' : 'Complete Registration'}
            </button>
          </form>
        </div>
      ) : (
        <>
          <h3>Please, check your email address to get your credentials. You can change them once connected.</h3>
          <p>Feel free to send feedback or ask for new features, we are listening!</p>
          <h1>Thank you for your trust!</h1>
          {userDetails && !isNewUser && (
            <>
              <p>Your email is: <strong>{userDetails.sub}</strong></p>
              <button onClick={handleStartClick} className="start-button">Start</button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Welcome;
