import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaTruck, FaSearch, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './LiveBuyForm.css';

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhone = (phone) => {
  const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return phoneRegex.test(phone);
};

const validateZip = (zip) => {
  const zipRegex = /^[0-9]{5}$/;
  return zipRegex.test(zip);
};

const LiveBuyForm = () => {
  const { publicToken } = useParams();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [step, setStep] = useState(1);

  const [customerData, setCustomerData] = useState(() => {
    const savedData = localStorage.getItem('shippingInformation');
    return savedData ? JSON.parse(savedData) : {
      email: '',
      shipping_address: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        country: 'France',
        state: '',
        zip: '',
        phone: ''
      }
    };
  });

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: '',
    zip: ''
  });

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [stripePublicKey, setStripePublicKey] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState('');

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(`/api/shopify/public/form/${publicToken}`);
        setFormData(response.data);
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to load form');
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, [publicToken]);

  useEffect(() => {
    if (!formData || !searchQuery.trim()) {
      setSearchResults([]);
      return;
    }
    
    const filtered = formData.products.filter(product =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filtered);
  }, [searchQuery, formData]);

  // Update the selectedVariants whenever selectedProducts change
  useEffect(() => {
    const variants = selectedProducts
      .filter(p => p.selectedVariant)
      .map(p => ({
        variant_id: p.selectedVariant.id,
        quantity: p.quantity
      }));
    setSelectedVariants(variants);
  }, [selectedProducts]);

  useEffect(() => {
    if (stripePublicKey && !stripe && window.Stripe) {
      console.log('Initializing Stripe with key:', stripePublicKey);
      const stripeInstance = window.Stripe(stripePublicKey);
      setStripe(stripeInstance);
    }
  }, [stripePublicKey, stripe]);

  const handleProductSelect = (product) => {
    if (!selectedProducts.find(p => p.id === product.id)) {
      setSelectedProducts([...selectedProducts, {
        ...product,
        selectedVariant: null,
        quantity: 1
      }]);
    }
    setSearchQuery('');
    setShowSearch(false);
  };

  const handleVariantSelect = (productId, variant) => {
    setSelectedProducts(prev => prev.map(p => 
      p.id === productId ? { ...p, selectedVariant: variant } : p
    ));
  };

  const handleQuantityChange = (productId, change) => {
    setSelectedProducts(prev => prev.map(p => {
      if (p.id === productId) {
        const newQuantity = Math.max(1, p.quantity + change);
        return { ...p, quantity: newQuantity };
      }
      return p;
    }));
  };

  const handleCustomerDataChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setCustomerData(prev => {
        const newData = {
          ...prev,
          [parent]: { ...prev[parent], [child]: value }
        };
        localStorage.setItem('shippingInformation', JSON.stringify(newData));
        return newData;
      });
    } else {
      setCustomerData(prev => {
        const newData = { ...prev, [field]: value };
        localStorage.setItem('shippingInformation', JSON.stringify(newData));
        return newData;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submission
    const errors = {
      email: validateEmail(customerData.email) ? '' : 'Format email invalide',
      phone: validatePhone(customerData.shipping_address.phone) ? '' : 'Format téléphone invalide',
      zip: validateZip(customerData.shipping_address.zip) ? '' : 'Code postal invalide'
    };

    setValidationErrors(errors);

    if (Object.values(errors).some(error => error) || selectedVariants.length === 0) {
      return;
    }

    try {
      // First, prepare payment data
      const preparePaymentData = {
        list_id: formData.list_id,
        variants: selectedVariants,
        customer_email: customerData.email,
        shipping_address: {
          name: `${customerData.shipping_address.first_name} ${customerData.shipping_address.last_name}`,
          line1: customerData.shipping_address.address1,
          line2: customerData.shipping_address.address2 || '',
          city: customerData.shipping_address.city,
          state: customerData.shipping_address.state || '',
          postal_code: customerData.shipping_address.zip,
          country: customerData.shipping_address.country
        }
      };

      console.log('Preparing payment with data:', preparePaymentData);
      
      const prepareResponse = await axios.post(`/api/shopify/public/form/${publicToken}/prepare_payment`, preparePaymentData);

      if (!prepareResponse.data || !prepareResponse.data.payment_intent_id || !prepareResponse.data.stripe_public_key) {
        throw new Error('Invalid prepare payment response: Missing required data');
      }

      console.log('Payment preparation successful:', prepareResponse.data);

      // Set Stripe public key first
      setStripePublicKey(prepareResponse.data.stripe_public_key);

      // Wait for stripe to be initialized
      if (!window.Stripe) {
        throw new Error('Stripe.js not loaded');
      }

      let stripeInstance = stripe;
      if (!stripeInstance) {
        stripeInstance = window.Stripe(prepareResponse.data.stripe_public_key);
        setStripe(stripeInstance);
      }

      setPaymentIntent(prepareResponse.data.payment_intent_id);

      // Create payment intent with shipping address
      const paymentIntentData = {
        payment_intent_id: prepareResponse.data.payment_intent_id,
        shipping_address: {
          name: `${customerData.shipping_address.first_name} ${customerData.shipping_address.last_name}`,
          line1: customerData.shipping_address.address1,
          line2: customerData.shipping_address.address2 || '',
          city: customerData.shipping_address.city,
          state: customerData.shipping_address.state || '',
          postal_code: customerData.shipping_address.zip,
          country: customerData.shipping_address.country
        }
      };

      console.log('Creating payment intent with data:', paymentIntentData);

      const paymentIntentResponse = await axios.post(
        `/api/shopify/public/form/${publicToken}/create_payment_intent`,
        paymentIntentData
      );

      if (!paymentIntentResponse.data || !paymentIntentResponse.data.client_secret) {
        throw new Error('Invalid payment intent response: Missing client_secret');
      }

      // Initialize payment element
      const options = {
        clientSecret: paymentIntentResponse.data.client_secret,
        appearance: {
          theme: 'stripe'
        }
      };

      const elementsInstance = stripeInstance.elements(options);
      if (!elementsInstance) {
        throw new Error('Failed to create Stripe elements instance');
      }

      const paymentElement = elementsInstance.create('payment');
      if (!paymentElement) {
        throw new Error('Failed to create payment element');
      }

      paymentElement.mount('#payment-element');
      setElements(elementsInstance);

    } catch (err) {
      console.error('Payment preparation error:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        fullError: err
      });
      setError(
        err.response?.data?.detail || 
        err.message || 
        'Failed to prepare payment. Please check the console for more details.'
      );
    }
  };

  const handlePaymentConfirmation = async () => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setPaymentError(null);
    setPaymentStatus(null);
    setPaymentMessage('');

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        setPaymentStatus('failed');
        setPaymentMessage(error.message);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        setPaymentStatus('success');
        setPaymentMessage('Payment successful! Thank you for your purchase.');
        
        // Reset form and redirect after 3 seconds
        setTimeout(() => {
          setPaymentStatus(null);
          setPaymentMessage('');
          setPaymentIntent(null);
          setSelectedProducts([]);
          setStep(1);
        }, 3000);
      }
    } catch (err) {
      setPaymentStatus('failed');
      setPaymentMessage('An unexpected error occurred.');
    } finally {
      setProcessing(false);
    }
  };

  const renderVariantOptions = (product) => {
    // Get unique colors and sizes
    const variants = product.variants;
    const colors = [...new Set(variants.map(v => v.title.split(' / ')[0]))];
    const sizes = [...new Set(variants.map(v => v.title.split(' / ')[1]).filter(Boolean))];

    return (
      <div className="live-buy-variants">
        <div className="live-buy-variant-colors">
          <h4 className="live-buy-variant-title">Color:</h4>
          <div className="live-buy-variant-options">
            {colors.map(color => {
              const variantWithColor = variants.find(v => v.title.startsWith(color));
              return (
                <button
                  key={color}
                  className={`live-buy-variant-button ${
                    product.selectedVariant?.title.startsWith(color) ? 'selected' : ''
                  }`}
                  onClick={() => handleVariantSelect(product.id, variantWithColor)}
                  disabled={!variantWithColor.available}
                >
                  {color}
                </button>
              );
            })}
          </div>
        </div>

        {sizes.length > 0 && (
          <div className="live-buy-variant-sizes">
            <h4 className="live-buy-variant-title">Size:</h4>
            <div className="live-buy-variant-options">
              {sizes.map(size => {
                const selectedColor = product.selectedVariant?.title.split(' / ')[0] || colors[0];
                const variantWithSize = variants.find(v => v.title === `${selectedColor} / ${size}`);
                return (
                  <button
                    key={size}
                    className={`live-buy-variant-button ${
                      product.selectedVariant?.title.endsWith(size) ? 'selected' : ''
                    }`}
                    onClick={() => handleVariantSelect(product.id, variantWithSize)}
                    disabled={!variantWithSize?.available}
                  >
                    {size}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (loading) return <div className="live-buy-loading">Loading...</div>;
  if (error) return (
    <div className="live-buy-wrapper">
      <div className="live-buy-container">
        <div className="live-buy-header">
          <h1>
            {error === 'Form not found or inactive' ? "This Live hasn't started yet" : error}
          </h1>
        </div>
      </div>
    </div>
  );

  return (
    <div className="live-buy-wrapper">
      <div className="live-buy-container">
        <div className="live-buy-header">
          <h1>{formData.list_name}</h1>
          {formData.description && <p>{formData.description}</p>}
        </div>

        {step === 1 && (
          <div className="live-buy-step-indicator">
            <span className="active">1. Products</span>
            <span onClick={() => setStep(2)}>2. Shipping</span>
          </div>
        )}
        {step === 2 && (
          <div className="live-buy-step-indicator">
            <span onClick={() => setStep(1)}>1. Products</span>
            <span className="active">2. Shipping</span>
          </div>
        )}

        {step === 1 ? (
          <>
            <div className="live-buy-search-container">
              <div className="live-buy-search-wrapper">
                <FaSearch className="live-buy-search-icon" />
                <input
                  type="text"
                  className="live-buy-search-input"
                  placeholder="Search products..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowSearch(true)}
                />
              </div>
              
              {showSearch && searchResults.length > 0 && (
                <div className="live-buy-search-results">
                  {searchResults.map(product => (
                    <div
                      key={product.id}
                      className="live-buy-search-item"
                      onClick={() => handleProductSelect(product)}
                    >
                      {product.images?.[0] && (
                        <img
                          src={product.images[0].url}
                          alt={product.title}
                          className="live-buy-search-item-image"
                        />
                      )}
                      <div className="live-buy-search-item-info">
                        <span className="live-buy-search-item-title">{product.title}</span>
                        <span className="live-buy-search-item-price">${product.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="live-buy-selected-products">
              {selectedProducts.map(product => (
                <div key={product.id} className="live-buy-product">
                  <div className="live-buy-product-header">
                    <div className="live-buy-product-info">
                      <img
                        src={product.images[0]?.url}
                        alt={product.title}
                        className="live-buy-product-image"
                      />
                      <h3 className="live-buy-product-title">{product.title}</h3>
                    </div>
                    <button
                      className="live-buy-remove"
                      onClick={() => setSelectedProducts(prev => 
                        prev.filter(p => p.id !== product.id)
                      )}
                    >
                      &times;
                    </button>
                  </div>

                  {renderVariantOptions(product)}
                </div>
              ))}
            </div>

            {selectedProducts.length > 0 && (
              <button 
                className="live-buy-button live-buy-button-primary"
                onClick={() => setStep(2)}
              >
                Continue to Shipping
              </button>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit} className="live-buy-customer">
            <h2 className="live-buy-customer-title">Shipping Information</h2>
            
            {Object.entries(customerData).map(([field, value]) => {
              if (typeof value === 'object') {
                return Object.entries(value).map(([subField, subValue]) => (
                  <div key={`${field}.${subField}`} className="live-buy-form-group">
                    <label className="live-buy-label">
                      {subField.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </label>
                    <input
                      type="text"
                      className="live-buy-input"
                      value={subValue}
                      onChange={(e) => handleCustomerDataChange(`${field}.${subField}`, e.target.value)}
                      required
                    />
                  </div>
                ));
              }
              return (
                <div key={field} className="live-buy-form-group">
                  <label className="live-buy-label">
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    type={field === 'email' ? 'email' : 'text'}
                    className="live-buy-input"
                    value={value}
                    onChange={(e) => handleCustomerDataChange(field, e.target.value)}
                    required
                  />
                </div>
              );
            })}

            {paymentIntent && (
              <div className="live-buy-payment">
                <div id="payment-element"></div>
                {paymentStatus && (
                  <div className="live-buy-payment-overlay">
                    <div className="live-buy-payment-message">
                      {paymentStatus === 'success' ? (
                        <>
                          <div className="live-buy-payment-icon success">
                            <FaCheckCircle />
                          </div>
                          <p>{paymentMessage}</p>
                        </>
                      ) : (
                        <>
                          <div className="live-buy-payment-icon error">
                            <FaTimesCircle />
                          </div>
                          <p>{paymentMessage || paymentError}</p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <button 
                  type="button"
                  className="live-buy-button live-buy-button-primary"
                  onClick={handlePaymentConfirmation}
                  disabled={!stripe || !elements || processing || paymentStatus === 'success'}
                >
                  {processing ? 'Processing...' : 'Pay Now'}
                </button>
              </div>
            )}

            <div className="live-buy-footer">
              <div className="live-buy-total">
                <div className="live-buy-shipping">
                  <FaTruck />
                  <span>Shipping: ${formData.shipping_fee.toFixed(2)}</span>
                </div>
                <div className="live-buy-total-amount">
                  Total: ${(
                    selectedProducts.reduce((sum, p) => 
                      sum + ((p.selectedVariant?.price || 0) * p.quantity), 0
                    ) + formData.shipping_fee
                  ).toFixed(2)}
                </div>
              </div>

              <div className="live-buy-actions">
                <button 
                  type="button" 
                  className="live-buy-button live-buy-button-secondary"
                  onClick={() => setStep(1)}
                >
                  Back
                </button>
                {!paymentIntent ? (
                  <button 
                    type="submit" 
                    className="live-buy-button live-buy-button-primary"
                  >
                    Checkout
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LiveBuyForm;
